import React, {useEffect, useState} from 'react';
import Layout from '@components/domain/shared/layout';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import Description from '@components/domain/course-details/description';
import EmptySpace from '@components/ui/empty-space';
import Spacing from '@components/ui/spacing';
import styles from '@styles/templates/curso-candidatura.module.scss';
import Spinner from '@components/ui/spinner';
import {HttpClient} from "@utilities/http-client";
import Submission from "@components/domain/form/submission";
import SEO from "@components/ui/seo";
import StaffAvailability from "@components/domain/staff-availability";

const ParticiparEquipa = () => {
    const [ formData, setFormData] = useState()
    const [ courses, setCourses] = useState();

    const [ submissionError, setSubmissionError ] = useState();
    const [ submitted, setSubmitted ] = useState(false);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const breakpoints = useBreakpoint();

    const httpClient = HttpClient(getAccessTokenSilently);
    const courseOptions = ["Não interessado", "Disponível", "Interessado", "Muito interessado"];


    // load availability
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

    }, [isAuthenticated]);

    // load courses
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        (async () => {
            const response = await httpClient(`courses/public`);
            if (response.ok)
                setCourses(await response.json());
        })();

        (async () => {
            const response = await httpClient(`auth/staff_availability`);
            if (response.ok) {
                const res = await response.json();
                setFormData(
                    Object.assign({},
                        ...res.courses_interested.map((x) => ({[`courses_interested_${x.course_id}`]: courseOptions[x.interest + 1]})))
                )
            }
        })();
    }, [isAuthenticated]);

    const onSubmit = () => {
        if (!submitted) {
            (async () => {
                try {
                    // eslint-disable-next-line no-unused-vars
                    const payload = {
                        courses_interested: Object.keys(formData).filter(key => key.startsWith("courses_interested_") && formData[key] !== courseOptions[0]).map(
                            y => {
                                for(let x of courses) {
                                    if (`courses_interested_${x.id}` === y)
                                        return {
                                            course_id: x.id,
                                            interest: courseOptions.indexOf(formData[y]) - 1
                                        };
                                }
                            })
                    };

                    const response = await httpClient(`auth/staff_availability`, {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(payload)
                    });

                    setSubmitted(true);

                    if (!response.ok) {
                        console.error("An error has occured.", response.status);
                        const errorJson = await response.json();
                        setSubmissionError(errorJson.message || true);
                        return;
                    }

                    setSubmissionError(false);
                } catch (error) {
                    console.error("An error has occured.", error);
                }
            })();
        }
    };

    return (
        <Layout extraFooterPadding={breakpoints.mobile ? 200 : 0}>
            <SEO title="Participa no After School" />

            <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />

            <div className={styles.container}>
                <EmptySpace desktop={{margin: 30}} mobile={{margin: 20}}/>
                <Spacing>
                    <div className={styles.metadata}>
                        <section className={styles.overview}>
                            <Description
                                title="Participa no After School"
                            />
                        </section>
                    </div>
                </Spacing>
                {courses && formData &&
                    <Spacing>
                        <>
                            {!submitted &&
                                <StaffAvailability
                                    courseOptions={courseOptions}
                                    data={formData}
                                    courses={courses}
                                    setData={setFormData}
                                />
                            }
                            {submitted && <Submission
                                loading={submissionError === undefined}
                                error={submissionError}
                                errorDescription="Ocorreu um erro ao submeter o formulário."
                                successDescription="Obrigado por nos indicares a tua disponibilidade e interesses."
                                successMessage="Resposta submetida com sucesso!"
                            />}
                            {!submitted &&
                                <>
                                    <div className={styles.controls}>
                                        <a className={styles.continue} onClick={onSubmit}>Submeter</a>
                                    </div>
                                </>
                            }
                        </>
                    </Spacing>
                }
                {(!courses || !formData) &&
                    <Spinner />
                }
            </div>
            <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
        </Layout>
    );
};

export default withAuthenticationRequired(ParticiparEquipa);
