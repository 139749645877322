import React from 'react';
import PropTypes from 'prop-types';
import Form from '@components/domain/form';

const StaffAvailability = ({ courses, courseOptions, data, setData }) => {
    const header = "<p>Este formulário serve para indicares a tua disponibilidade e interesse em participar no After School.</p>" +
        "<p></p>" + "<p>Abaixo encontra-se uma lista de cursos nos quais podes participar. A lista está dividida em cursos onde podes participar como Instrutor e cursos onde podes participar como Autor. Ao preencheres o formulário com as tuas disponibilidades e preferências, considera a seguinte lógica:</p><ul><li><b>Não interessado</b> - não gosto do tema ou não tenho conhecimentos suficientes</li><li><b>Disponível</b> - sem grande preferência, posso ser membro desta equipa</li><li><b>Interessado</b> - preferência por este curso</li><li><b>Muito interessado</b> - forte preferência por este curso</li></ul>" + "<p></p>" + "<p>As tuas respostas são não vinculativas. Iremos sempre contactar-te antes de abrirmos qualquer curso para confirmar o teu interesse.</p>" + "<p></p>"

    const getFields = type => courses.filter(c => c.looking_for === type).map(x => (
        { "id": `courses_interested_${x.id}`, "type": "radio",
            "label": `${x.name} (${x.short_slug})`, "multiple": false, "options":
            courseOptions,
            "required": false, "defaultValue": courseOptions[0]
        }));

    const instrutores_fields = getFields("instrutores");
    const autores_fields = getFields("autores");

    const questions = [
        instrutores_fields.length ?
        {
            "title": "Cursos - Instrutor",
            "description":  ` 
<p>Os Instrutores ensinam a matérias a alunos motivados. Todo o material necessário já está preparado, pelo que "basta aparecer". Em geral, cada curso tem 7 aulas e cada instrutor costuma dar metade das aulas (3 ou 4), podendo dar mais aulas se assim o desejar. As aulas têm 2 horas e, normalmente, ocorrem ao sábado em horário a definir conforme a disponibilidade da equipa. Consulta o currículo de cada curso <a href="https://treetree2.school/cursos/" target="_blank">aqui</a>.</p>
            `,
            "fields": instrutores_fields
        } : null,
        autores_fields.length ? {
            "title": "Cursos - Autor",
            "description":  ` 
<p>Os Autores criam conteúdos para novos cursos After School. Os cursos são introdutórios, com material tipicamente universitário, mas numa versão menos extensa e complexa. O mais comum é os cursos terem 7 aulas e cada autor é inserido numa equipa responsável por construir 1 aula. Uma vez criadas as aulas, será feito um processo de revisão. A criação de uma aula demora cerca de 10 horas e pode ser feita de forma assíncrona.</p>
            `,
            "fields": autores_fields
        } : null
    ].filter(q => q !== null);
    return (
        <Form
            header={header}
            questions={questions}
            values={data}
            onChange={setData}
        />
    );
};

export default StaffAvailability;

StaffAvailability.propTypes = {
    header: PropTypes.string,
    data: PropTypes.object,
    courses: PropTypes.arrayOf(PropTypes.object),
    setData: PropTypes.func
};
